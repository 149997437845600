<template>
  <el-card  shadow="hover" class="item-card-box">
    <div @click="$emit('click')" class="flex-def flex-zTopBottom flex-zBetween" style="height: 120px">
      <div class="flex-def flex-zBetween">
        <div>
          <div class="item-title" :style="titleColorShow"><slot name="title"></slot></div>
          <div class="item-num"><slot></slot></div>
        </div>
        <el-image class="item-icon" :src="elIcon(icon)"></el-image>
      </div>
      <div class="item-tips"><slot name="tips"></slot></div>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "dashboardItem",
  props:{
    type:{
      type:String,
      default:""
    },
    icon:{
      type:String,
      default:"",
    },
  },
  computed:{
    titleColorShow(){
      let color = "#000000";
      switch (this.type){
        case "success":
          color = "#008100";
          break;
        case "warning":
          color = "#ee8400";
          break;
        case "primary":
          color = "#008fd6";
          break;
        case "error":
          color = "#f30000";
          break;
      }
      return `color:${color};`
    }
  },
  methods:{
    elIcon(name) {
      let str = "";
      if (process.env.NODE_ENV === "production"){
        str = `/static/web/dashboard/${name}.png`;
      }else {
        str = `./${process.env.BASE_URL}dashboard/${name}.png`
      }
      return str
    },
  }
}
</script>

<style scoped>
.item-card-box{
  cursor: pointer;
  margin-top: 25px;
}
.item-icon{
  width: 70px;
  height: 70px;
}
.item-num{
  font-size: 36px;
  font-weight: 600;
  margin-top: 5px;
}
.item-title{
  font-weight: 600;
  font-size: 1.1rem;
}
.item-tips{
  line-height: 0;
  font-weight: 600;
  padding-bottom: 5px;
}
</style>